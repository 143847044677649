body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.filter-ctrl {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  width: 140px;
}

.filter-ctrl input[type="text"] {
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  width: 100%;
  border: 0;
  height: 20px;
  margin: 0;
  padding: 5px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
